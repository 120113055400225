import { useContext, useEffect, useState } from "react";
import { AnimeContext } from "../context/AnimeContext";
import { Anime } from "./Anime";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Loading } from "./Loading";

export function AnimeList() {
    const { baseUrl, setAnimeFLVOn } = useContext(AnimeContext);
    const [animeList, setAnimeList] = useState({});
    const navigate = useNavigate();
    let { page } = useParams();
    page = Number(page);
    const [maxPages, setMaxPage] = useState(150);
    const [isLoading, setIsLoading] = useState(false); // Nuevo estado

    async function getAnimeList(page = 1) {
        try {
            const response = await fetch(baseUrl + "list/" + page, {
                method: "GET",
            });

            const data = await response.json();

            if (response.status === 503) {
                console.error("AnimeFLV esta caida", data.error);
                setAnimeFLVOn(false);
            }

            if (response.status !== 200) {
                console.error("Error al obtener los animes:", data.error);
                return;
            }

            return data;
        } catch (error) {
            console.error("Error al obtener los animes:", error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const animes = await getAnimeList(page);
                setAnimeList(animes);
            } catch (error) {
                console.error("Error al obtener los animes:", error);
            } finally {
                setIsLoading(false); 
            }
        };

        fetchData();
    }, [page]);

    function back() {
        if (isLoading) return;
        page--;
        setMaxPage(animeList.total_pages);
        navigate(`/list/${page}`);
    }

    function next() {
        if (isLoading) return;
        page++;
        setMaxPage(animeList.total_pages);
        navigate(`/list/${page}`);
    }

    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {animeList.animes &&
                        animeList.animes.map((anime) => (
                            <Anime key={anime.id} {...anime} />
                        ))}
                </div>
            )}

            {/* Botones */}
            <div className="flex justify-center space-x-2 mt-4">
                {page > 1 && (
                    <button
                        className="p-2 rounded bg-yellow-500 text-gray-900 mb-10"
                        onClick={() => back()}
                        disabled={isLoading}
                    >
                        Anterior
                    </button>
                )}
                <span className="p-2 rounded bg-yellow-500 text-gray-900 mb-10">
                    {page}
                </span>
                {page < maxPages && (
                    <button
                        className="p-2 rounded bg-yellow-500 text-gray-900 mb-10"
                        onClick={() => next()}
                        disabled={isLoading}
                    >
                        Siguiente
                    </button>
                )}
            </div>
        </>
    );
}
