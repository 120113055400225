import React, { createContext, useState } from "react";

// Creamos el contexto
export const AnimeContext = createContext();

// Creamos el proveedor del contexto
export function AnimeProvider(props) {
    const [baseUrl] = useState("https://anime.ninjakito.com/");
    const [animeFLVOn, setAnimeFLVOn] = useState(true);

    return (
        <AnimeContext.Provider
            value={{ baseUrl, animeFLVOn, setAnimeFLVOn}}
        >
            {props.children}
        </AnimeContext.Provider>
    );
}
