import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const NavBar = () => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(`Buscando el anime: ${search}`);
        navigate(`/search/${search}`);
    };

    return (
        <>
            <nav className="fixed top-0 w-full bg-gray-900 text-yellow-500 p-4 flex justify-between">
                <div>
                    <h1 className="text-xl">NinjaFLV</h1>
                    <form onSubmit={handleSubmit} className="flex items-center">
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Buscar anime"
                            className="mr-4 p-2 rounded bg-gray-800 text-white"
                        />
                        <button
                            type="submit"
                            className="p-2 rounded bg-yellow-500 text-gray-900"
                        >
                            Buscar
                        </button>
                    </form>
                </div>
                <div>
                    <Link
                        to={"/list/1"}
                        className="p-2 rounded bg-yellow-500 text-gray-900 m-2"
                    >
                        List
                    </Link>
                    <Link
                        to="/"
                        className="p-2 rounded bg-yellow-500 text-gray-900 m-2"
                    >
                        Últimos Animes
                    </Link>
                </div>
            </nav>
            <div className="mb-20"></div>
        </>
    );
};
