import { useContext } from "react";
import { AnimeContext } from "../context/AnimeContext";
import { Anime } from "./Anime";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Loading } from "./Loading";

export function AnimeSearch() {
    let { name } = useParams();
    const [animeSearch, setAnimeSearch] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { baseUrl, setAnimeFLVOn } = useContext(AnimeContext);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const animes = await searchAnime(name);
                setAnimeSearch(animes);
            } catch (error) {
                console.error("Error al obtener los animes:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [name]);

    async function searchAnime(name) {
        try {
            const response = await fetch(baseUrl + "search/" + name, {
                method: "GET",
            });

            const data = await response.json();

            if (response.status === 503) {
                console.error("AnimeFLV esta caida", data.error);
                setAnimeFLVOn(false);
            }

            if (response.status !== 200) {
                console.error("Error al obtener los animes:", data.error);
                return;
            }

            return data;
        } catch (error) {
            console.error("Error al obtener los animes:", error);
        }
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {animeSearch.map((anime) => (
                        <Anime key={anime.id} {...anime} />
                    ))}
                </div>
            )}
        </>
    );
}
