import { Link } from "react-router-dom";

export function PageNotFound() {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-900 text-yellow-500">
            <div className="text-center">
                <h1 className="text-6xl mb-4">Página No Encontrada</h1>
                <p className="text-xl mb-8">Lo sentimos, la página que estás buscando no existe.</p>
                <Link to="/" className="p-2 rounded bg-yellow-500 text-gray-900">
                    Ir al Inicio
                </Link>
            </div>
        </div>
    );
}