import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { AnimeProvider } from "./context/AnimeContext";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <BrowserRouter> 
            <AnimeProvider>
                <main className="bg-gray-800">
                    <App />
                </main>
            </AnimeProvider>
        </BrowserRouter>
    </React.StrictMode>
);