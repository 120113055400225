import { useContext, useState } from "react";
import { useEffect } from "react";
import { AnimeContext } from "../context/AnimeContext";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Loading } from "./Loading";

export function AnimeDetails() {
    let { id } = useParams();
    const { baseUrl, setAnimeFLVOn } = useContext(AnimeContext);
    const [animeInfo, setAnimeInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const animes = await getAnimeInfo(id);
                setAnimeInfo(animes);
            } catch (error) {
                console.error("Error al obtener los animes:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);

    async function getAnimeInfo(id) {
        try {
            const response = await fetch(baseUrl + "anime/" + id, {
                method: "GET",
            });

            const data = await response.json();

            if (response.status === 503) {
                console.error("AnimeFLV esta caida", data.error);
                setAnimeFLVOn(false);
            }

            if (response.status !== 200) {
                console.error("Error al obtener los animes:", data.error);
                return;
            }

            return data;
        } catch (error) {
            console.error("Error al obtener los animes:", error);
        }
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="flex flex-col items-center justify-center bg-gray-900 text-yellow-500 p-4">
                    <h1 className="text-4xl mb-4">{animeInfo.title}</h1>
                    <h2 className="text-2xl mb-2">
                        {animeInfo.nombres_alternativos &&
                            animeInfo.nombres_alternativos.map(
                                (nombre_alternativo) => (
                                    <li key={nombre_alternativo}>
                                        {nombre_alternativo}
                                    </li>
                                )
                            )}
                    </h2>
                    <img
                        src={animeInfo.banner}
                        alt={animeInfo.title}
                        className="w-full object-cover rounded-lg shadow-md mb-4"
                    />
                    <p className="text-lg mb-4">{animeInfo.synopsis}</p>
                    <h2 className="text-2xl mb-2">Debut: {animeInfo.debut}</h2>
                    {/* Generos */}
                    <h2 className="text-2xl mb-2">Géneros</h2>
                    <ul className="flex flex-wrap justify-center mb-4">
                        {animeInfo.genres &&
                            animeInfo.genres.map((genero) => (
                                <li
                                    key={genero}
                                    className="p-2 rounded bg-yellow-500 text-gray-900 m-2"
                                >
                                    {genero}
                                </li>
                            ))}
                    </ul>
                    {/* Episodios */}
                    <h2 className="text-2xl mb-2">Episodios</h2>
                    <div className="flex flex-wrap justify-center mb-4">
                        {animeInfo.episodes &&
                            animeInfo.episodes.map((episode) => (
                                <div key={episode.episode} className="m-2">
                                    <img
                                        src={episode.image_preview}
                                        alt={`Episodio ${episode.episode}`}
                                        className="w-32 object-cover rounded-lg shadow-md mb-2"
                                    />
                                    <p className="text-center text-yellow-500">
                                        Episodio {episode.episode}
                                    </p>
                                </div>
                            ))}
                    </div>
                    {/* Historia Paralela */}
                    <div className="mb-4">
                        {animeInfo.historia_paralela &&
                            animeInfo.historia_paralela.length > 0 && (
                                <>
                                    <h2 className="text-2xl mb-2">
                                        Historia paralela
                                    </h2>
                                    {animeInfo.historia_paralela.map(
                                        (historia) => (
                                            <Link
                                                key={historia.id}
                                                to={`/anime/${historia.id}`}
                                                className="bg-yellow-500 text-gray-900 rounded p-2 m-2"
                                            >
                                                {historia.title}
                                            </Link>
                                        )
                                    )}
                                </>
                            )}
                        {/* Historia Principal */}
                        {animeInfo.historia_principal &&
                            animeInfo.historia_principal.length > 0 && (
                                <>
                                    <h2 className="text-2xl mb-2">
                                        Historia principal
                                    </h2>
                                    {animeInfo.historia_principal.map(
                                        (historia) => (
                                            <Link
                                                key={historia.id}
                                                to={`/anime/${historia.id}`}
                                                className="bg-yellow-500 text-gray-900 rounded p-2 m-2"
                                            >
                                                {historia.title}
                                            </Link>
                                        )
                                    )}
                                </>
                            )}
                        {/* Precuela */}
                        {animeInfo.precuela &&
                            animeInfo.precuela.length > 0 && (
                                <>
                                    <h2 className="text-2xl mb-2">Precuela</h2>
                                    {animeInfo.precuela.map((historia) => (
                                        <Link
                                            key={historia.id}
                                            to={`/anime/${historia.id}`}
                                            className="bg-yellow-500 text-gray-900 rounded p-2 m-2"
                                        >
                                            {historia.title}
                                        </Link>
                                    ))}
                                </>
                            )}
                        {/* Secuela */}
                        {animeInfo.secuela && animeInfo.secuela.length > 0 && (
                            <>
                                <h2 className="text-2xl mb-2">Secuela</h2>
                                {animeInfo.secuela.map((historia) => (
                                    <Link
                                        key={historia.id}
                                        to={`/anime/${historia.id}`}
                                        className="bg-yellow-500 text-gray-900 rounded p-2 m-2"
                                    >
                                        {historia.title}
                                    </Link>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
