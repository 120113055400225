import { Link } from "react-router-dom";

export function Anime({ id, title, description, poster }) {
    return (
        <div className="bg-gray-800 text-white rounded-lg overflow-hidden shadow-lg m-4 cursor-pointer">
            <Link key={id} to={`/anime/${id}`}>
                <img
                    className="w-full h-64 object-cover object-center rounded-t-lg"
                    src={poster}
                    alt="Anime"
                />
                <div className="p-6">
                    <h2 className="text-2xl font-bold mb-2">{title}</h2>
                    <p className="text-gray-300">{description}</p>
                </div>
            </Link>
        </div>
    );
}
