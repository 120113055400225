import { useContext, useEffect, useState } from "react";
import { AnimeContext } from "../context/AnimeContext";
import { Anime } from "./Anime";
import { Loading } from "./Loading";

export function AnimeLatest() {
    const { baseUrl, setAnimeFLVOn } = useContext(AnimeContext);
    const [animeLatest, setAnimeLatest] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const animes = await getLatestAnimes();
                setAnimeLatest(animes);
            } catch (error) {
                console.error("Error al obtener los animes:", error);
            } finally {
                setIsLoading(false); 
            }
        };

        fetchData();
    }, []);

    async function getLatestAnimes() {
        try {
            const response = await fetch(baseUrl + "latestanimes", {
                method: "GET"
            });

            const data = await response.json();

            if (response.status === 503) {
                console.error("AnimeFLV esta caida", data.error);
                setAnimeFLVOn(false);
            }

            if (response.status !== 200) {
                console.error("Error al obtener los animes:", data.error);
                return;
            }

            return data;
        } catch (error) {
            console.error("Error al obtener los animes:", error);
        }
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {animeLatest.map((anime) => (
                        <Anime key={anime.id} {...anime} />
                    ))}
                </div>
            )}
        </>
    );
}
