import { useState, useEffect } from 'react';

export function Loading() {
    const [dots, setDots] = useState('.');

    useEffect(() => {
        const timer = setInterval(() => {
            setDots(dots => {
                return dots.length < 3 ? dots + '.' : '.';
            });
        }, 500); // Cambia los puntos cada 500 milisegundos

        return () => clearInterval(timer); // Limpia el intervalo cuando el componente se desmonta
    }, []);

    return (
        <div className="flex items-center justify-center h-screen bg-gray-900 text-yellow-500 cursor-wait">
            <div className="text-center">
                <h1 className="text-6xl mb-4">Cargando{dots}</h1>
            </div>
        </div>
    );
}