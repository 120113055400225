import React, { useContext } from "react";
import { Route, Routes, Link } from "react-router-dom";
import { AnimeSearch } from "./components/AnimeSearch";
import { AnimeList } from "./components/AnimeList";
import { AnimeDetails } from "./components/AnimeDetails";
import { NavBar } from "./components/NavBar";
import { PageNotFound } from "./components/PageNotFound";
import { AnimeFLVOffline } from "./components/AnimeFLVOffline";
import { AnimeContext } from "./context/AnimeContext";
import { AnimeLatest } from "./components/AnimeLatest";

export function App() {
    const { animeFLVOn } = useContext(AnimeContext);

    if (!animeFLVOn) {
        return (
            <>
                <NavBar />
                <AnimeFLVOffline />
            </>
        );
    }

    return (
        <>
            <NavBar />
            <Link to="/">Inicio</Link>
            <Link to="/anime">Anime</Link>
            <Routes>
                <Route path="/" exact element={<AnimeLatest />} />
                <Route path="/list/:page" exact element={<AnimeList />} />
                <Route path="/search/:name" exact element={<AnimeSearch />} />
                <Route path="/anime/:id" exact element={<AnimeDetails />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </>
    );
}